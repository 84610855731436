export type AuditTarget = 'Applications'
  | 'AuditLogs'
  | 'AuthEmail'
  | 'AuthGoogle'
  | 'AuthTwitter'
  | 'Credits'
  | 'Credentials'
  | 'DisputedCredits'
  | 'FacebookPages'
  | 'FeaturedPosts'
  | 'Posts'
  | 'Users'
  | 'Roles'
  | 'Tags'
  | 'ContactUser'
  | 'SocialLink'
  | 'Matches'
  | 'MatchPosts'
  | 'Milestones'
  | 'Teams'
  | 'TeamMembers'
  | 'TeamAccounts'
  | 'TeamSubscriptions'
  | 'Lists'
  | 'Jobs'
  | 'ListMembers'
  | 'ListUsers'
  | 'ListComments'
  | 'ListVotes'
  | 'ImageHashes'
  | 'Products'
  | 'PaymentMethods'
  | 'PinnedPosts'
  | 'SimilarImages'
  | 'UserSubscriptions'
  | 'UserTiktokProfiles'
  | 'UserTwitterProfiles'
  | 'InstagramAccounts'
  | 'YoutubeChannels'
  | 'Invoices'
  | 'Profile'
  | 'PostingAccount'
  | 'UserTwitchProfiles'
  | 'MergeUsers'
  | 'Upgrade'
  | 'Signup'
  | 'UserData'
  | 'UnsupportedImport'
  | 'AutoCredits'
  | 'SwitchToManager'
  | 'SwitchToProfile'
  | 'ApplicationQuestions'
  | 'ApplicationAnswers'
  | 'ExternalApplication'
  | 'InternalApplication'
  | 'UserStripeCustomer'
  | 'ShareContent'
  | 'Concepts'
  | 'ConceptComments'
  | 'Projects'
  | 'Link'
  | 'PostCard'
  | 'CreatorCard'
  | 'MediaComparison'
  | 'MediaComparisonResult'
  | 'MediaComparisonGroup'
  | 'ContentListItem'
  | 'ContentList'
  | 'ExternalApiPermission'
  | 'FavoritePost'
  | 'CreatorTags'
  | 'UserSettings'
  | 'TagValues'
  | 'UserPermissions'
  | 'UserStripeCustomers'
  | 'NetworkAccounts'
  | 'SuggestedCredits'
  | 'UserThreadsProfiles'
  | 'Connections'
  | 'HeardAbout'
  | null;

export type AuditAction = 'create' | 'update' | 'delete' | 'read' | 'list' | 'login' | 'logout' |
  'click' | 'view' | 'welcomed' | 'featured' | 'upsert' | 'signinError' | 'signupError';

export const ACTIONS: { [action: string]: AuditAction } = {
  create: 'create',
  update: 'update',
  upsert: 'upsert',
  delete: 'delete',
  read: 'read',
  list: 'list',
  login: 'login',
  logout: 'logout',
  click: 'click',
  view: 'view',
  welcomed: 'welcomed',
  featured: 'featured',
  signinError: 'signinError',
  signupError: 'signupError',
};

type EventValue = string | number;

export type EventData = Record<string, EventValue>;

/**
 * ShareEventData is used for sending share events to the backend
 * for tracking. content is required and it could be something like
 * 'replay2023', 'thank-you', 'milestone', etc.
 * 
 * Any other keys are optional and can be used to send additional
 * data to the backend.
 */
export type ShareEventData = Record<'content' | string, EventValue>;

export type PostCardClickContext = 'featured-carousel' | 'explore' | 'profile' | 'my-gondola' | 'manager' | 'match' | 'project-page';

export type CreatorCardClickContext = 'featured-home' | 'creator-search' | 'creator-search-featured' |
  'creator-list-card' | 'creators-landing' | 'replay' | 'milestones' | 'credits' | 'job-card' | 'job-detail';

export type FavoritePostClickContext = 'post-detail' | 'post-card' | 'post-menu';
